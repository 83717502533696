import { VaccineIds } from "../../_shared/shared.enums";

export function IsInvalidBatch(vaccineId, batchNumber): boolean {
    const value = batchNumber;
    if (vaccineId && value) {
        switch (vaccineId) {
            case VaccineIds.PfizerCovidOriginalOmicronBA4to5: {
                return !/^[A-Z]{2}[0-9]{4}$/.test(value);
            }
            case VaccineIds.PfizerComirnatyOmicronXBBAge12Plus: {
                return !/^[A-Z]{2}[0-9]{4}$/.test(value);
            }
            case VaccineIds.PfizerComirnatyOmicronXBB6monthsTo4Years: {
                return !/^[A-Z]{2}[0-9]{4}$/.test(value);
            }
            case VaccineIds.PfizerComirnatyOmicronXBB5To11Years: {
                return !/^[A-Z]{2}[0-9]{4}$/.test(value);
            }
            case VaccineIds.ModernaSpikevaxXBB: {
                return !(/^[0-9]{6}[A-Z]{1}$/.test(value) || /^[A-Z]{2}[0-9]{4}[A-Z]{1}$/.test(value));
            }
            default: {
                return value?.length < 1 || value?.length > 20;
            }
        }
    }
    return false;
}